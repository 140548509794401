.register-form-main-sec{
    background-image: linear-gradient(#afa07c 50%, #eeeeee 50%);
    font-family: 'Open Sans', sans-serif;
    padding-top: 50px;
    padding-bottom: 50px;
   
}
.register-form-col-one{
    background: #fff;
}
.csa-heading{
    text-align: center;
    font-weight: 600;
    padding-top: 10px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.csa-head-p{
    text-align: center;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.register-form-second-row{
    padding-left: 30px;
    padding-right:30px;
    padding-bottom: 30px;
}
.main-register-a{
    float: right;
    background: #d14227;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: 1px;
    // 
    border: none;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 3.5px;
    position: relative;
    z-index: 1;
    padding: 5px 12px;
}
.main-register-a:hover{
    text-decoration: none;
    color: #fff;
}
.register-form-select{
    float: right;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
    margin-top: 5px;
 
}
.register-form-select-text-col{
    padding: 0;
}
.register-form-select-text{
    float: right;
    padding: 0;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.register-form-promo{
    color: #637ee9;
    font-weight: 600;
}
.register-form-general{
  
    font-weight: 600;
    padding-top: 10px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}
.order-summary-div{
    background: #eeeeee;
    height: 240px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;

}
.music-start-img-register-form{
    width: 100%;
    height: 370px;
}
.register-form-mny{
    float: right;
    
}
.contact-info-reg{
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.order-summary-h5{
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.total-span{
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.multiple-addmission{
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.total-span-mny{
    font-weight: 600;
    float: right;
}
.sale-end-p{
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.csa-head-p-one{
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.csa-heading-one{
    padding-top: 10px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}