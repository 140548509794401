.prost-bar-menu-one-sec{
    background: #fef9ed;
    padding-top: 20px;
    padding-bottom: 30px;
}
.bar-menu-tabs-row{
    margin-top: 75px;
}
.bar-menu-tabs-row{
    .nav.nav-tabs {
        float: left;
        display: block;
        margin-right: 20px;
        border-bottom:0;
        border-right: 1px solid #ddd;
        padding-right: 15px;
    }
    .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        background: #fff;
        font-size: 55px;

    }
    
    .nav-tabs .nav-link.active {
        color: #f2e6d6;
        // background-color:#007bff !important;
        background-image: linear-gradient( to right,#150603 90%, #fff 10%) !important;
        border-color: #fff !important;
    
    }
    .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 0rem!important;
        border-top-right-radius: 0rem!important;
        font-size:50px;
        font-family: 'Burford Rustic Book Black';
        letter-spacing: 0.28px;
        line-height: 60px;
        color: #140603;
        padding-left: 20px;
    }
    .tab-content>.active {
        display: block;
        background: transparent;
        // min-height: 165px;
    }
    .menu-heading{
      font-size: 60px;
      font-family: 'Burford Rustic Outline';
      letter-spacing: 1.5px;
      color: #90724f;
      margin-top: -30px;
    }
    .menu-sub-heading{
        font-size: 24px;
        font-family: 'Burford Rustic Book Black';
        line-height: 25px;
        letter-spacing: 1.2px;
        color: #272725;
        font-weight: 200;
    }
    .bar-menu-hr{
        background-color: #adadad;
        margin-top: 35px;
    }
    .menu-p{
        font-size: 14px;
        letter-spacing: 1.2px;
        line-height: 25px;
      
    }
    .tab-pane{
        height: 950px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .nav.nav-tabs {
        float: left;
        display: block;
        // margin-right: 20px;
        border-bottom: 0;
        border-right: 1px solid transparent;
        // padding-right: 15px;
        width: 100%;
        background-color: #fff;
        padding-top: 25px;
        
    }
    
.tab-pane::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.tab-pane::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}
.tab-pane::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #8c5626;
}

  .slider-li{
    padding-left: 20px;
    padding-bottom: 20px;
  }
// //   animation zoom start 
// @-webkit-keyframes zoom {
//     from {
//       -webkit-transform: scale(1, 1);
//     }
//     to {
//       -webkit-transform: scale(1.5, 1.5);
//     }
//   }
  
//   @keyframes zoom {
//     from {
//       transform: scale(1, 1);
//     }
//     to {
//       transform: scale(1.5, 1.5);
//     }
//   }
//   .carousel-inner .item > img {
//     -webkit-animation: zoom 20s;
//     animation: zoom 20s;
//   }
// //animation zoom end
// //fade start
//   .carousel-fade .carousel-item {
//     opacity: 0;
//     transition-duration: .6s;
//     transition-property: opacity;
//    }
   
//    .carousel-fade  .carousel-item.active,
//    .carousel-fade  .carousel-item-next.carousel-item-left,
//    .carousel-fade  .carousel-item-prev.carousel-item-right {
//      opacity: 1;
//    }
   
//    .carousel-fade .active.carousel-item-left,
//    .carousel-fade  .active.carousel-item-right {
//     opacity: 0;
//    }
   
//    .carousel-fade  .carousel-item-next,
//    .carousel-fade .carousel-item-prev,
//    .carousel-fade .carousel-item.active,
//    .carousel-fade .active.carousel-item-left,
//    .carousel-fade  .active.carousel-item-prev {
//     transform: translateX(0);
//     transform: translate3d(0, 0, 0);
//    }
   //fade end
   .item img{
    width: 100%;
    height: 100%;
   }

}
// prost bar menu main start
.flip-right-arrow-img{
    max-width: 50px;
    margin-top:-70%;
    margin-right: -100px;
    float: right;
    cursor: pointer;
}
.flip-left-arrow-img{
    max-width: 50px;
    cursor: pointer;
    margin-top: -140%;
    margin-left: -100px;
}
.page img{
    width: 100%;
    height: 100%;
}
// prost bar menu main end
.book-container{
    height: 1350px !important;
}
.book-container div{
    height: 1350px !important;
}
.bar-menu-main-head{
    color:#dd7400;
    font-family: 'Burford Rustic Book Bold';
    font-size: 80px;
    letter-spacing: 1.5px;
    line-height: 60px;
    text-align: center;
    position: absolute;
    top:30px;
    left:380px;
}

