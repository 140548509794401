.contact-main-sec{
    background-image: linear-gradient( to right,#9d8e68 50%, #e5dec2 50%);
    padding-top: 20px;
    padding-bottom: 30px;
}
// .contact-one-col{
//     background-color: #9d8e68;

// }
// .contact-two-col{
//     background-color: #e5dec2;
// }
.contact-get-h2{
    margin-top: 65px;
    font-size: 79px;
    // line-height: 60px;
    // padding: 10px 0;
    letter-spacing: 1.98px;
    color:#fff;
    font-family: 'Burford Rustic Book Ultra Light';
}
.contact-touch-h2{
    font-size:158px;
    line-height: 50px;
    // margin-top:-68px;
    // padding: 10px 0;
    letter-spacing: 3.50px;
    color: #f4d386;
    font-family: 'Burford Rustic Book Bold';
}
.huuricane-img{
    margin-top:-150px;
}
.leave-info-h6{
    color: #8f7052;
    font-size: 24px;
    font-family: 'Burford Rustic Book Black';
    letter-spacing: 0.12px;
    margin-bottom: 0;
    margin-top: 65px;
    // margin-left: 100px;
}
.contact-info-h6{
    // position: relative;
    // z-index: 1;
    color: #fff;
    font-size: 24px;
    font-family: 'Burford Rustic Book Black';
    letter-spacing: 0.12px;
    margin-bottom: 0;
    margin-top: 80px;
}
.contact-two-sec{
    padding-top: 30px;
    padding-bottom: 30px;
}
.phone-contact:hover{
    color:#fff;
}
.mail-contact:hover{
    color: #fff;
}
.contact-call{
    max-width: 25px;
}
.contact-envelop{
    max-width: 25px;
}
.contact-placeholder{
    max-width:25px;
}
.address-contact:hover{
    color:#fff
}
.phone-contact{
    color: #fff;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.12px;
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 15px;
    
}
.mail-contact{
    color: #fff;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.12px;
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 15px;
    
}
.phone-info-p{
    margin-top: 35px;

}
.mail-info-p{
    margin-top:15px;

}
.address-contact{
    color: #fff;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.12px;
    margin-bottom: 0;
    font-weight: 600;
    
}
.name-input{
    border:none;
    border-bottom: 1px solid #aa9d76;
    color: #000;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.12px;
    background: transparent;
    margin-top: 30px;
}
.phone-input{
    border:none;
    border-bottom: 1px solid #aa9d76;
    color: #000;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.12px;
    background: transparent;
    margin-top: 30px;
}
.message-input{
    border:none;
    border-bottom: 1px solid #aa9d76;
    color: #000;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.12px;
    background: transparent;
    margin-top: 30px;
}
.email-input{
    border:none;
    border-bottom: 1px solid #aa9d76;
    color: #000;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.12px;
    background: transparent;
    margin-top: 30px;
}
.contact-two-col{
    padding-left: 100px;
}
.leave-message-btn{
    background: #fff;
    border-color: #402d26;
    padding: 2px 15px;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.6px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    margin-top: 25px;
  }
  .contactvalidation .form-control:focus{
    background:transparent;
    // transition: color 0.25s;
    transition: left 3000ms ease-out;
    border-bottom:2px solid #000;
    box-shadow: none;

  }
  .phone-contact:hover{
    text-decoration: none;
  }
  .mail-contact:hover{
    text-decoration: none;
  }
  .address-contact:hover{
    text-decoration: none;
  } 
  .map-width{
      width:100%;
      height: 450px;
  }

    /* Sweep To Top */
    .hvr-sweep-to-top {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        //
        background: #fff;
        border-color: #402d26;
        padding: 2px 15px;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.6px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 200;
        margin-top: 25px;
    }
    .hvr-sweep-to-top:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #8f7052;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
        color: #fff;
    }
    .hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
    .huuricane-img-view{
        display: block;
    }