
    @font-face {
    font-family: 'Burford Rustic Book Black';
    font-style: normal;
    font-weight: normal;
    src: local('Burford Rustic Book Black'), url('../fonts/Burford Rustic Book Black.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Burford Rustic Book Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Burford Rustic Book Bold'), url('../fonts/Burford Rustic Book Bold.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Burford Rustic Book Light';
    font-style: normal;
    font-weight: normal;
    src: local('Burford Rustic Book Light'), url('../fonts/Burford Rustic Book Light.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Burford Rustic Book Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Burford Rustic Book Ultra Light'), url('../fonts/Burford Rustic Book Ultra Light.woff') format('woff');
    }
    
    
    @font-face {
        font-family: 'Burford Rustic Book Black';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Book Black'), url('../fonts/Burford Rustic Book Black.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Book Bold';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Book Bold'), url('../fonts/Burford Rustic Book Bold.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Book Light';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Book Light'), url('../fonts/Burford Rustic Book Light.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Book Ultra Light';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Book Ultra Light'), url('../fonts/Burford Rustic Book Ultra Light.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Extrude One A';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Extrude One A'), url('../fonts/Burford Rustic Extrude One A.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Extrude One B';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Extrude One B'), url('../fonts/Burford Rustic Extrude One B.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Extrude Two B';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Extrude Two B'), url('../fonts/Burford Rustic Extrude Two B.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Extrude Two A';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Extrude Two A'), url('../fonts/Burford Rustic Extrude Two A.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Inline Bold';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Inline Bold'), url('../fonts/Burford Rustic Inline Bold.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Avenir Next LT Pro Medium Italic';
        font-style: normal;
        font-weight: normal;
        src: local('Avenir Next LT Pro Medium Italic'), url('../fonts/AvenirNextLTPro-MediumIt.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Inline Light';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Inline Light'), url('../fonts/Burford Rustic Inline Light.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Line Bold';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Line Bold'), url('../fonts/Burford Rustic Line Bold.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Line Light';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Line Light'), url('../fonts/Burford Rustic Line Light.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Line Medium';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Line Medium'), url('../fonts/Burford Rustic Line Medium.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Ornaments A';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Ornaments A'), url('../fonts/Burford Rustic Ornaments A.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Ornaments B';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Ornaments B'), url('../fonts/Burford Rustic Ornaments B.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Outline';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Outline'), url('../fonts/Burford Rustic Outline.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Shadow One A';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Shadow One A'), url('../fonts/Burford Rustic Shadow One A.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Shadow One B';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Shadow One B'), url('../fonts/Burford Rustic Shadow One B.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Shadow Two A';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Shadow Two A'), url('../fonts/Burford Rustic Shadow Two A.woff') format('woff');
        }
        
        
        @font-face {
        font-family: 'Burford Rustic Shadow Two B';
        font-style: normal;
        font-weight: normal;
        src: local('Burford Rustic Shadow Two B'), url('../fonts/Burford Rustic Shadow Two B.woff') format('woff');
        }
    
        @font-face {
            font-family: 'Beautiful People Personal Use Regular';
            font-style: normal;
            font-weight: normal;
            src: local('Beautiful People Personal Use Regular'), url('../fonts/Beautiful People Personal Use.woff') format('woff');
        }
    
    
        @font-face {
            font-family: 'Beautiful People Personal Use Regular';
            font-style: normal;
            font-weight: normal;
            src: local('Beautiful People Personal Use Regular'), url('../fonts/Beautiful People two Personal Use.woff') format('woff');
        }
        
        @import url('https://fonts.googleapis.com/css?family=Poppins:400,700,900&display=swap');
     @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import "includes/custom";
@import "includes/bootstrap";
@import "custom/animate";
@import "custom/landing";
@import "custom/navbar";
@import "custom/home";
@import "custom/hydhome";
@import "custom/prostbarmenu";
@import "custom/social";
@import "custom/rocky-road";
@import "custom/register-description";
@import "custom/register-form";
@import "custom/contact";
@import "custom/location";
@import "custom/footer";
@import "custom/mobile";