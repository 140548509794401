.register-description-main-sec{
    background-image: linear-gradient(#afa07c 50%, #eeeeee 50%);
   font-family: 'Open Sans', sans-serif;
    padding-top: 50px;
    padding-bottom: 50px;
   
}
.summit-col{
    background-color: #eeeeee;
    padding: 10px;
    padding-top: 40px;
    padding-left: 40px;
}
.register-col{
    padding: 0;
}
.music-start-img-register{
    width:100%;
    height: 500px;
}
.register-mnth{
    margin-bottom: 0;
    font-weight: 600;
}
.register-date{
    font-weight: 600;
}
.register-csa{
    font-weight: 600;
}
.description-eight-h5{
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.values-float{
    float: right;
}
.main-register-btn{
    background: #3f8746;
    color: #fff;
    text-align: center;
    text-decoration: none;
    // margin-top:170px;
    // 
  
    border: none;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 3.5px;
    position: relative;
    z-index: 1;
    padding: 5px 12px;
}
.main-register-btn:hover{
text-decoration: none;
color: #fff;
}
.free-div{
    margin-top: 290PX;
}
.description-row{
    padding-left: 50px;
    padding-top: 20px;
    background: #fff;
}
.description-eight-p{
    // font-size:16px;
    // letter-spacing:1px;
    // line-height: 24px;
    // 
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    // text-align: center;
    // padding-top: 40px;
    font-family: 'Open Sans', sans-serif;
    
}
.register-follow{
    border:1px solid #90a7f0;
    color: #90a7f0;
    text-decoration: none;
    background: #fff;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 2px;
}
.register-follow:hover{
    text-decoration: none;

}
.register-four-date{
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    font-family: 'Open Sans', sans-serif;
}
.register-four-loc{
    font-weight: 600;
}
.register-map-padd{
    padding: 0;
}
.register-row-mrg{
    margin-bottom: -10px;
}
.csa-head-p-one-modal{
    max-width: 300px;
    }