.footer-top-sec{
    background-color: #2a2a2a;
    padding-top: 75px;
    padding-bottom: 75px;
}
.footer-h5{
    color: #fff;
    font-size: 24px;
    line-height: 60px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    font-family: Burford Rustic Book Black;
}
.footer-input{
    max-width: 320px;
    padding-right: 120px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.footer-subcribe-btn{
    background-color: #f4d386;
    color: #2a2a2a;
    padding:8px 28px  8px 28px;
    font-weight: 600;
    margin-left: -4px;
    font-size: 14px;
    letter-spacing: 0.35px;
    border:1px solid #f4d386;
}
.insta-img{
    margin: 4px;
}
.fb-img{
    margin: 4px;
}
.prost-img{
    margin: 4px;
}
.linked-img{
    margin: 4px;
}
.footer-ul li a{
  cursor:pointer;
}
// .footer-top-sec-first-col{
//     max-width: 480px;
//     margin: 0 auto;
// }
.footer-top-sec-two-col{
    // max-width: 480px;
    // margin: 0 auto;
margin-top: 28px;
}
.footer-top-sec-two-col-h5{
    font-size:24px;
    line-height: 18px;
    letter-spacing: 1.2px;
    color: #fff;
    font-family: Burford Rustic Book Black;
    // padding-bottom: 10px;
    
    padding-top: 20px;
}
.footer-top-sec-two-col-h6{
    font-family: Burford Rustic Book Black;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.9px;
    color:#fff;
    // margin-top: 18px;
    margin-bottom: 0;
    
}
.hyd-phn-mrg{
  margin-top:40px;
}
.footer-top-sec-two-col-h5-new{
  color: #fff;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 1.2px;
  color: #fff;
  font-family: Burford Rustic Book Black;
 
}
.main-footer-h5{
    font-size: 24px;
    line-height: 30px;
    color: #b8b8b8;
    font-family: Burford Rustic Book Black;
}
.footer-two-sec{
    background-color: #222222;
    padding-top: 85px;
    padding-bottom: 30px;
}
.footer-ul{
    padding-left: 0;
    padding-top: 15px;
}
.footer-ul li{
    color: #5c5c5c;
    font-size:14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    list-style: none;
    // text-transform: uppercase;
}
.footer-loc-icon{
    font-size: 24px;
    color: #5c5c5c;
    padding:5px;

}
.footer-phone-icon{
    font-size: 18px;
    color: #5c5c5c;
    padding:5px;

}
.footer-bang-address{
    color:#5c5c5c;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    padding-top: 15px;
}
.footer-number{
    color: #5c5c5c;
    font-size:14px;
    line-height: 22px;
    letter-spacing: 0.35px;
    text-decoration: none;
}
.axlr-logo-img{
    margin-bottom: 12px;
}
.business-img{
    max-width: 38px;
}
.footer-mail{
    color: #5c5c5c;
    font-size:14px;
    line-height: 22px;
    letter-spacing: 0.35px;
    text-decoration: none;
    margin-top: 10px;
}
.hyd-contact{
margin-top:108px;
margin-bottom: 5px;
}
.footer-top-border{
    border-top: 1px solid #414141;
    padding: 15px 0;
}
.footer-top-border p{
    color: #585858;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}
.copy-right-p{
    color: #585858;
    font-size:14px;
    line-height: 24px;
    margin-bottom: 0;
}
.footer-powered{
    color: #fff;
    font-size:14px;
    line-height: 24px;
    // margin-bottom: 0;
}
.footer-a:hover{
    text-decoration: none;
    color: #5c5c5c;
}
.footer-a{
    text-decoration: none;
    color: #5c5c5c;
}
.new-timings-styles{
  border:1px solid #fff;
  text-align: center;
  border-radius: 15px;
  padding-bottom: 25px;

}
.new-timings-styles-h5{
  text-align: center;
  padding-bottom: 15px;
}
// success modal start
.formsucess_modalsucess{
    svg {
        width: 71px!important;
        display: block;
        margin: 40px auto 0;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
      }
      .path.circle {
        -webkit-animation: dash 0.9s ease-in-out;
        animation: dash 0.9s ease-in-out;
      }
      .path.line {
        stroke-dashoffset: 1000;
        -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
        animation: dash 0.9s 0.35s ease-in-out forwards;
      }
      .path.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
        animation: dash-check 0.9s 0.35s ease-in-out forwards;
      }
      p {
        text-align: center;
        margin: 20px 0 60px;
        font-size: 1.25em;
      }
      p.success {
        color: #73AF55;
      }
      p.error {
        color: #D06079;
      }
      @-webkit-keyframes dash {
        0% {
          stroke-dashoffset: 1000;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes dash {
        0% {
          stroke-dashoffset: 1000;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
      @-webkit-keyframes dash-check {
        0% {
          stroke-dashoffset: -100;
        }
        100% {
          stroke-dashoffset: 900;
        }
      }
      @keyframes dash-check {
        0% {
          stroke-dashoffset: -100;
        }
        100% {
          stroke-dashoffset: 900;
        }
      }
    }
// success modal end

