.image-zoom {
    position: relative;
    overflow: hidden;
    background-color: #000;
    background-image: url("../images/hyd.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
  }
  .image-zoom .hyd-loc-p {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 24px;
    font-family: Burford Rustic Book Black;
    letter-spacing: 0.12px;
    margin-bottom: 0;
    margin-top: 230px;
    margin-left: 28px;
  }
  .image-zoom .hyd-loc-h3{
    margin-top: -12px;
    top:-10px;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 55px;
    font-family: Burford Rustic Book Black;
    letter-spacing: 0.28px;
    margin-bottom: 0;
    margin-left: 28px;
  }
  
  .image-zoom-one {
    position: relative;
    overflow: hidden;
    background-color: #000;
    background-image: url("../images/bng2.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
  }
  .image-zoom-one .hyd-loc-p {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 24px;
    font-family: Burford Rustic Book Black;
    letter-spacing: 0.12px;
    margin-bottom: 0;
    margin-top: 230px;
    margin-left: 28px;
  }
  .image-zoom-one .hyd-loc-h3{
    position: relative;
    top:-10px;
    margin-top: -12px;
    z-index: 1;
    color: #fff;
    font-size: 55px;
    font-family: Burford Rustic Book Black;
    letter-spacing: 0.28px;
    margin-bottom: 0;
    margin-left: 28px;
  }
  .image-zoom-two {
    position: relative;
    overflow: hidden;
    background-color: #000;
    background-image: url("../images/bang-two.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
  }
  .image-zoom-two .hyd-loc-p {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 24px;
    font-family: Burford Rustic Book Black;
    letter-spacing: 0.12px;
    margin-bottom: 0;
    margin-top: 230px;
    margin-left: 28px;
  }
  .image-zoom-two .hyd-loc-h3{
    position: relative;
    top:-10px;
    z-index: 1;
    margin-top: -12px;
    color: #fff;
    font-size: 55px;
    font-family: Burford Rustic Book Black;
    letter-spacing: 0.28px;
    margin-bottom: 0;
    margin-left: 28px;
  }
 
  .zoom-in::after,
  .zoom-out::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform .4s ease-in-out;
  }
  
  .zoom-in:focus::after, .zoom-in:hover::after {
    transform: scale(1.05);
  }
  
  .zoom-out::after {
    transform: scale(1.05);
  }
  .zoom-out:focus::after, .zoom-out:hover::after {
    transform: scale(1);
  }
  
.image-zoom {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 40vw;
    height: 370px;
    // margin: 1vw;
    cursor: pointer;
  }
    
.image-zoom-one {
    cursor: pointer;
    height: 370px;
    // margin: 1vw;
  }
  .image-zoom-two {
    cursor: pointer;
    height: 500px;
    // margin: 1vw;
  }
//   .image-zoom:nth-child(2n) {
//     background-image: url("../images/hyd-loc.png");

//   }
.second-sec{
    background-color: #eeeeee;
    padding-top: 100px;
    // padding-bottom: 100px;
}
.hyd-loc-btn{
    // top: 10px;
    background: #fff;
    border:none;
    color: #040301;
    // font-family: Burford Rustic Book Black;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight:500;
    letter-spacing: 3.5px;
    position: relative;
    z-index: 1;
   margin-top: 10px;
   padding: 5px 12px;
    margin-left: 32px;
}
.loc-hyd-icon{
  // top:10px;
  margin-left:10px;

    color: #fff;
   font-size: 24px;
    position: relative;
    z-index: 1;
}
.loc-direction{
  // top: 10px;
  margin-left: 8px;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 17px;
    letter-spacing: 3.5px;
    line-height: 30px;
    font-weight: 100;
    font-family: 'Open Sans', sans-serif;
}
// animation start
		/**/
    .fischer-tastes .animated-discs {
      position: relative;
      height: 550px;
      // height: auto;
  }
  .fischer-tastes .animated-discs .music-disc,
  .fischer-tastes .animated-discs .film-disc {
      width: 205px;
      // height: 1200px;
      // height: auto;
      overflow: hidden;
  }
   .fischer-tastes .animated-discs .music-disc img {
      -webkit-animation: rotateLeft 40s linear infinite;
      -moz-animation: rotateLeft 40s linear infinite;
      animation: rotateLeft 40s linear infinite;
  }
  .fischer-tastes .animated-discs .music-disc img,
  .fischer-tastes .animated-discs .film-disc img {
      -webkit-transform-origin: center;
      -moz-transform-origin: center;
      -ms-transform-origin: center;
      -o-transform-origin: center;
      transform-origin: center;
      max-width: none;
  }
  .fischer-tastes .animated-discs .music-disc {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
  }
  .fischer-tastes .animated-discs .music-disc,
  .fischer-tastes .animated-discs .film-disc {
      position: absolute;
      left: 50%;
      top:15%;
  }
  
  .beer-img {
      max-width: 100%;
  }
  .pizza-img {
    max-width: 100%;
}
// img{
//   max-width: 100%;
// }

  .fischer-tastes .animated-discs .film-disc img {
      -webkit-animation: rotateLeft 40s linear infinite;
      -moz-animation: rotateLeftt 40s linear infinite;
      animation: rotateLeft 40s linear infinite;
  }
  .fischer-tastes .animated-discs .film-disc img{
  margin-left:-205px;
  }
  .fischer-tastes .animated-discs .music-disc img,
  .fischer-tastes .animated-discs .film-disc img {
      -webkit-transform-origin: center;
      -moz-transform-origin: center;
      -ms-transform-origin: center;
      -o-transform-origin: center;
      transform-origin: center;
      max-width: none;
  }
  
//animation end
.rotate-one-h5{
color: #3e2821;
font-size: 60px;
line-height: 60px;
font-family: Burford Rustic Book Black;
letter-spacing: 1.5px;
text-align: right;
}
.rotate-one-span{
  color: #ea920c;
  font-size: 58px;
  line-height: 60px;
  font-family: Burford Rustic Book Black;
  letter-spacing: 1.3px;
  
}
.rotate-two-h5{
  color: #3e2821;
  font-size: 58px;
  line-height: 58px;
  font-family: Burford Rustic Book Black;
  letter-spacing: 1.3px;
  }
  .rotate-two-span{
    color: #e40c06;
    font-size: 60px;
    line-height: 60px;
    font-family: Burford Rustic Book Black;
    letter-spacing: 1.5px;
  }

.rotate-one-col{
  padding-top: 200px;
}
.rotate-sec{
  background-image: url("../images/wood-bg-prost.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
  padding-top: 40px;
}
.rotate-two-col{
  padding-left: 60px
}
.whats-happen-sec{
  padding: 100px 0;
}
.what-happen-h3{
  text-align: center;
  color: #f4d386;
  // font-family: 'Burford Rustic Outline';
  font-family: 'Burford Rustic Book Bold';
  font-size:60px;
  line-height: 72px;
  letter-spacing: 3px;
  margin-top: -50px;
}
.whats-happen-sec{
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("../images/leather.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.what-happen-three{
width: 100%;
}
.what-happen-two{
  width: 100%;
}
.what-happen-one{
 width: 100%;
}
.what-happen-col-one{
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  background-image: url('../images/what-happen-one.png');
  // background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  max-width: 100%;
  cursor: pointer;
}
.what-happen-col-two{
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  background-image: url('../images/what-happen-two.png');
  // background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  max-width: 100%;
  cursor: pointer;
}
.what-happen-col-three{
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  background-image: url('../images/what-happen-three.png');
  // background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
 max-width: 100%;
  cursor: pointer;
}
.home-banner-main{
  background-image: url("../images/home-banner.jpg");
  background-repeat: no-repeat;
  background-size:100% 100%; 
  // height:100vh;
  padding-top: 30px;
  padding-bottom: 10px;
}
.home-p1{
  margin-top: 32px;
  font-size:50px;
  line-height: 60px;
  letter-spacing: 1.25px;
  // font-family: BurfordRusticInline-1;
  font-family: 'Burford Rustic Outline';
  color: #fff;


}
.home-p2{
  margin-top: 32px;
  font-size: 79px;
  line-height: 0;
  // padding: 10px 0;
  letter-spacing: 1.98px;
  color:#fff;
  font-family: 'Burford Rustic Book Ultra Light';
}
.home-p3{
  font-size:198px;
  line-height: 0;
  // margin-top:-68px;
  // padding: 10px 0;
  letter-spacing: 4.95px;
  color: #f4d386;
  font-family: 'Burford Rustic Inline Bold';
}
.home-p5{
  margin-top: 40px;
  line-height: 0;
  letter-spacing: 4.13px;
  color: #fff;
  font-size: 165px;
  font-family: 'Burford Rustic Book Ultra Light';
}

.ampersent-span{
  font-family: 'Burford Rustic Outline';
  font-size: 198px;
  line-height: 120px;
  letter-spacing: 4.95px;
  color: #fff;
  margin-left:42px;
}
.the-span{
  color: #fff;
  font-family: 'Burford Rustic Outline';
  line-height: 0;
  letter-spacing: 1.5px;
  font-size: 60px;

}
.hand-img{
  width:100%;
  float: right;
 
}
.home-container-fluid{
  padding: 0;
  overflow: hidden;
}
.home-container-col{
 max-width: 425px;
 margin: 0 auto;
}
.ingredients-h3{
  color:#dd7400;
  font-family: 'Burford Rustic Book Bold';
  font-size: 60px;
  letter-spacing: 1.5px;
  line-height: 60px;
  text-align: center;
  // font-weight:600;
}
.ingredients-p{
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1.4px;
  color: #494340;
  text-align: center;
  padding-top: 40px;
  font-family: 'Open Sans', sans-serif;
}
.beer-glass-col{
  margin: 0 auto;
  text-align: right;
  background-image: linear-gradient(transparent 80%, #eeeeee 20%);
  padding-bottom: 100px;

}
.beer-loc-glass{
  margin-left: 130px;
}
// parallax start
.parallax-ingredients{
   /* The image used */
   background-image: url('../images/ingradient-bg.jpg');
 
   /* Full height */
  //  height: 100%;
   height:1187px; 
   width: 100%;
  // max-width: 100px;
  // width:322px;

  // margin: 0 auto;
   /* Create the parallax scrolling effect */
   background-attachment: fixed;
   background-position: center;
   background-repeat: no-repeat;
   background-size:100% 100%;
   
}
.parallax-sec-loc{
  background: #eeeeee;
  overflow: hidden;
}

.parallax-beer-loc{
  background: #eeeeee;
  overflow: hidden;
}
.box-ingradients{
  position: sticky;
  position: -webkit-sticky;
  top:50px;
}
.loc-ingradients-box-two{
  background-color: #fff;
  max-width: 402px;
  float: left;
}
.box-ingradients-two{
  position: sticky;
  position: -webkit-sticky;
  top:250px;
}
.loc-ingradients-box{
  background-color: #fff;
  max-width: 402px;
 
  float: right;
  // float: right !important;
  // margin-right: 0 !important;
}
.loc-ingradients-box-h3{
  color: #150602;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.2px;
  padding-top: 32px;
  padding-left: 20px;
  padding-bottom: 30px;
  font-family: 'BurfordRusticBook';
}
.loc-ingradients-box-p{
  color: #494340;
  letter-spacing: 1.6px;
  line-height: 30px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 30px;
  padding-left: 20px;
  
}
.loc-ingredients-data{
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 630px;
  margin: 0 auto;
}

// .bar-menu-btn-side{
//   background: #fff;
//   border-color: #402d26;
//   padding: 0px 12px;
//   font-size: 16px;
//   text-transform: uppercase;
 
//   // float: right;
//   line-height: 30px;
//   letter-spacing: 2.4px;
//   font-family: 'Open Sans', sans-serif;
//   // font-weight: 200;
//   margin-top: 25px;
// }
.bar-menu-a{
  text-decoration: none;
}
.eatery-menu-a{
  text-decoration: none;
}

//parrallax end
.main-h1{
  position: relative;
  }
  
  .main-h1-span1{
  font-family: 'Burford Rustic Outline';
  font-size: 50px;
  line-height: 50px;
  position: absolute;
  top:0;
  }
  .main-h1-span2{
  font-family: 'Burford Rustic Book Ultra Light';
  font-size: 79px;
  line-height: 79px;
  position: absolute;
  top:115px;

  }
  .main-h1-span3{
  font-family: 'Burford Rustic Inline Bold';
  font-size: 198px;
  line-height: 198px;
  position: absolute;
  top:120px;
  margin-left: -10px;
  }
  .main-h1-span4{
  font-family: 'Burford Rustic Outline';
  font-size: 198px;
  position: absolute;
  top: 180px;
  left: 35px;
  line-height: 198px;
  color: #fff;
  }
  .main-h1-span5{
  font-family: 'Burford Rustic Outline';
  font-size: 60px;
  position: absolute;
  top: 295px;
  line-height: 60px;
  left: 160px;
  }
  .main-h1-span6{
  font-family: 'Burford Rustic Book Ultra Light';
  font-size: 165px;
  position: absolute;
  top: 382px;
  line-height: 165px;
  }
  .main-ingradients-sec{
    background-color: #eeeeee;
    padding-bottom: 50px;
  }
 .melt-img{
   width: 100%;
 }
 .plus-ingradients{
   color: #150602;
   font-size: 36px;
    line-height: 60px;
    letter-spacing: 0.18px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
 }
 .glass-text{
  color: #3e2821;
  font-size: 30px;
  line-height: 30px;
  font-family: Burford Rustic Book Black;
  letter-spacing: 1.5px;
  // text-align: right;
 }
 .glass-col{
   margin-top:300px;
 }
 .beer-glass{
   margin-right: 15px;
 }

//  
 /* Sweep To Top */
 .bar-menu-btn-side {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  //
  background: #fff;
  border-color: #402d26;
  padding: 0px 12px;
  font-size: 16px;
  text-transform: uppercase;
 
  // float: right;
  line-height: 30px;
  letter-spacing: 2.4px;
  font-family: 'Open Sans', sans-serif;
  // font-weight: 200;
  margin-top: 25px;
}
.bar-menu-btn-side:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #8f7052;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.bar-menu-btn-side:hover,.bar-menu-btn-side:focus, .bar-menu-btn-side:active {
  color: #fff;
}
.bar-menu-btn-side:hover:before, .bar-menu-btn-side:focus:before, .bar-menu-btn-side:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

// 
  // .bar-menu-btn{
  //   background: #fff;
  //   border-color: #402d26;
  //   padding: 0px 12px;
  //   font-size: 16px;
  //   float: right;
  //   line-height: 30px;
  //   letter-spacing: 2.4px;
  //   font-family: 'Open Sans', sans-serif;
  //   // font-weight: 200;
  //   margin-top: 25px;
  // }
// 
 /* Sweep To Top */
 .bar-menu-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  //
  background: #fff;
  border-color: #402d26;
  padding: 0px 12px;
  font-size: 16px;
  float: right;
  line-height: 30px;
  letter-spacing: 2.4px;
  font-family: 'Open Sans', sans-serif;
  // font-weight: 200;
  margin-top: 25px;
}
.bar-menu-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #8f7052;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.bar-menu-btn:hover,.bar-menu-btn:focus, .bar-menu-btn:active {
  color: #fff;
}
.bar-menu-btn:hover:before, .bar-menu-btn:focus:before, .bar-menu-btn:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
// 
.eatery-menu-btn{
  background: #fff;
  border-color: #402d26;
  padding: 0px 12px;
  font-size: 16px;
  // float: right;
  line-height: 30px;
  letter-spacing: 2.4px;
  font-family: 'Open Sans', sans-serif;
  // font-weight: 200;
  margin-top: 25px;
}
/* Sweep To Top */
.eatery-menu-btn{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  //
  background: #fff;
  border-color: #402d26;
  padding: 0px 12px;
  font-size: 16px;
  // float: right;
  line-height: 30px;
  letter-spacing: 2.4px;
  font-family: 'Open Sans', sans-serif;
  // font-weight: 200;
  margin-top: 25px;
}
.eatery-menu-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #8f7052;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.eatery-menu-btn:hover,.eatery-menu-btn:focus, .eatery-menu-btn:active {
  color: #fff;
}
.eatery-menu-btn:hover:before, .eatery-menu-btn:focus:before, .eatery-menu-btn:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.loc-direction:hover{
  text-decoration: none;
}
// 
.prost-logo-head{
  margin-top: 20px;
}
// 
.dir-a:hover{
  text-decoration: none;
}
// 

.modal-center{
  text-align: center;
}
.s-p-hyd:hover{
  text-decoration: none;
}
.hyd-a:hover{
  text-decoration: none;
}
.bang-a:hover{
  text-decoration: none;
}
// 
.choose-loc-modal{
  margin-bottom: 0;
  font-family: 'Burford Rustic Book Bold';
  font-size: 55px;
  line-height: 60px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  // line-height: 1.6;
  letter-spacing: 0.14px;
  // text-align: center;
  color: #150602;
}