.sidenav {
  height: -webkit-fill-available;
  width: 0;
  position: fixed;
  z-index:99999;
  top: 0;
  right: 0;
  background-color: #fff8ed;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  text-align:center;
}

.sidenav a {
  text-decoration: none;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 55px;
  // margin-left: 50px;
  z-index: 1;
  color: #150602;
}

// @media screen and (max-height: 450px) {
//   .sidenav {padding-top: 15px;}
//   .sidenav a {font-size: 18px;}
// }

//   menu

.sidebar-beer{
  width: 100%
}
.n-active{
  background-color: #150602;
  width: fit-content;
  color: #f2e6d6 !important;
  line-height: 0 !important;
  padding: 10px !important;
  padding-bottom: 30px !important;
  padding-top: 15px !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
.n-item{
  font-family: 'Burford Rustic Book Bold';
  font-size: 40px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  color: #150602;
  margin-top: -12px;
  display: inherit;
  padding-top: 10px;
  padding-left: 10px !important;
}
.fallow-side{
  font-family: 'Burford Rustic Book Bold';
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  margin-left: 18px;
  color: #9a824a;
}
.side-img-link{
  width: 30px;
  margin: 8px;
}
.side-t-menu{
  margin-left: 40px;
}
// .side-nav-m-t{
//   margin-top: 10px;
// }
.s-p-hyd{
  margin-bottom: 0;
  font-family: 'Burford Rustic Book Bold';
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.14px;
  text-align: center;
  color: #150602;
}
.sub-p-hyd{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.07px;
  text-align: center;
  color: #150602;
}
.column1 {
  float: left;
  width: 20%;
  padding: 5px;
}
.header-img{
  padding-left: 12px;
}
.padd-sider{
  padding-left: 30px;
}
.slider-placement{
  max-width: 400px;
}
#slider-placement .owl-prev {
  margin: 0 0px;
  height: 16px;
  width: 20px;
  // background-image: url(../images/long-arrow-pointing-to-left@2x.png);
  background-image: url(../images/vector-smart-object-copy-18.png);
  background-repeat: no-repeat;
  background-size: cover;
  float: right;
  margin-top: -300px;
  background-size: 16px;
  margin-right: 25px;
  position: absolute;
  left: 10px;
  transform: rotate(180deg);
  background-position-y: center;
}
#slider-placement .owl-next{
  margin: 0 0px;
  height: 16px;
  width: 20px;
  // background-image: url(../images/long-arrow-pointing-to-left-copy@2x.png);
  background-image: url(../images/vector-smart-object-copy-18.png);
  background-repeat: no-repeat;
  background-size: cover;
  float: right;
  margin-top: -300px;
  background-size: 16px;
  margin-right: -9px;
  position: absolute;
  right: 15px;
  background-position-y: center;
}
#slider-placement div>button>span {
  display: none;
}
.Location-sidebar-M{
  display: block;
}
.Location-sidebar-l{
  display: none;
}

//
// .slider-inner img {
//   width: 191px;
//   height: 494px;
// }

.carousel-control-prev{
  opacity: 1;
}
.carousel-control-prev:hover{
  opacity: 1;
}
.carousel-control-next{
  opacity: 1;
}
.carousel-control-next:hover{
  opacity: 1;
}
.nav-item a:hover{
  color: #d64d27;
}
// 
.menu-slider-bg{
  background-image: url("../images/menu-glass-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
}
.new-loc-col{
  background-color: #f2ebe1;
  padding:20px; 
  
}
.choose-text{
  color: #000;
  font-size: 55px;
  font-family: 'Burford Rustic Book Black';
  letter-spacing: 0.90px;
  margin-bottom: 0;
  line-height: 60px;
  font-weight: 100;

}
.jublee-img{
    margin-top: 40px;
}
.whitefield-img{
  margin-top: 40px;
}
.desktop-view{
  display: inline-block;
}
.mobile-view{
  display: none;
}