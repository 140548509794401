@media only screen and (max-width: 768px)  {
    .desktop-view{
        display: none;
      }
      .mobile-view{
          display: block;
      }
      .main-img-col{
          text-align: center;
      }
      .extra-col{
          display: none;
      }
    .text-img{
        margin-top: 50px;
        padding: 25px;
    }
    .melt-img{
        width: auto;
    }
    .register-phone{
        margin-top: 15px;

    }
    .map-width{
        width:365px;
        height: 200px;
    }
    // .contact-main-sec{
    //     background-image: none;
    //     background: #fef9ed;
    // }
    .contact-main-sec{
        background-color: #fff;
        background-image: none;
    }
    .contact-touch-h2{
        font-size: 100px;
    }
    .rotate-one-col{
        padding-top: 0;
    }
    .rotate-one-h5{
        text-align: left;
    }
    .bar-menu-btn{
        float: left;
    }
    .rotate-two-col{
        padding-left: 10px;
    }
    .footer-subcribe-btn{
        margin-top: 0px;
        padding: 7.5px !important;
    }
    .rotate-col{
        margin-top:-100px;
        margin-bottom: -100px;
    }
    .location-col-mobile{
        padding: 15px;
    }
    .hand-img{
        padding-left: 15px;
    }
    .beer-img{
        max-width: none;
        transform-origin: center;
    }
    .pizza-img {
        max-width: none;
        transform-origin: center;
    }
    .fischer-tastes .animated-discs .music-disc {
        -webkit-transform: translateX(-75%) scale(.5);
        -moz-transform: translateX(-75%) scale(.5);
        -ms-transform: translateX(-75%) scale(.5);
        -o-transform: translateX(-75%) scale(.5);
        transform: translateX(-75%) scale(.5);
    }
    .fischer-tastes .animated-discs .film-disc {
        -webkit-transform: translateX(-25%) scale(.5);
        -moz-transform: translateX(-25%) scale(.5);
        -ms-transform: translateX(-25%) scale(.5);
        -o-transform: translateX(-25%) scale(.5);
        transform: translateX(-25%) scale(.5);
    }
    .langing-up-flex-semantic{
        padding-left: 0px;
    }
    .iam21-img{
        width: 100%;
    }
    .location-hyd{
        top: 0;
        right: 12px;
    }
    .hyd-loc-video{
        height: auto;
    }
    .toGether-beer{
        font-size: 115px;
    }
    .our-Beer-sec{
        border-left: 0px solid gray;
        margin-left: 0px;
    }
    .box-sec{
        padding-bottom: 50px;
        padding-top: 15px;
    }
    .mobile-bear-glass{
        display: block;
    }
    .mobile-bear-glass-desk{
        display: none;
    }
    .our-Beer-sec .video-text{
        padding: 2px;
        text-align: center;
        width: 100%;
    }
    .box-content{
        padding-top: 30px;
        text-align: center;
    }
    .mobile-ingradients-lk{
        display: none;
    }
    .mobile-ingradients-lm{
        text-align: center;
        width: 100%;
    }
    .semicircel{
        display: none;
    }
    .semicircel-sor{
        display: none;
    }
    .glass-col{
        margin-top: 0px;
        text-align: center;
        padding-bottom: 40px;
    }
    .rotate-one-h5{
        font-size: 57px;
    }
    .rotate-two-h5{
        font-size: 50px;
    }
    .footer-input{
        max-width: 71%;
        padding-top: 4.9px;
    }
    .footer-subcribe-btn{
        padding: 8px 28px 7px 28px;
    }
    .hyd-contact{
        margin-top: 0px;
    }
    .footer-ban-t{
        padding-top: 30px;
    }
    .footer-lop{
        text-align: center !important;
    }
    .rock-road-h3{
        font-size: 55px;
    }
    .beeer-details-glass{
        right: 28%;
        width: 40%;
    }
    .rocky-road-col{
        text-align: center;
        padding-top: 30px;
        padding-left: 15px; 
    }
    .alc-p{
        max-width: initial;
        margin: 1px 40%;
        margin-bottom: 20px;
    }
    .footer-two-sec{
        padding-top: 50px;
    }
    .rocky-road-sec-two{
        text-align: center;
    }
    .contact-get-h2{
        color: #795548;
    }
    .contact-info-h6{
        color: #795548;
    }
    .phone-contact{
        color: #795548;
    }
    .mail-contact{
        color: #795548;
    }
    .address-contact{
        color: #795548;
    }
    .huuricane-img{
        margin-top: -400px;
        opacity: .2;
        padding-left: 25%;
    }
    .huuricane-img-view{
        display: none;
    }
    .contact-two-col{
        padding-left: 15px;
    }
    .gallery-width{
        width:100% !important;
    }
    .bar-menu-tabs-desk{
        display: none;
    }
    .bar-menu-tabs-mob{
        display: block;
    }
    .bar-menu-img-1{
        width: 100%;
        margin-top: 10px;
    }
    .bar-menu-tabs-row{
        margin-top: 45px;
    }
    .ingredients-c{
        font-size: 50px;
    }
    .beer-glass{
        margin-right: 15px;
        width: 100%;
    }
    .semicircel1{
        display: none;
    }
    .toGether-beer-main{
        font-size: 120px;
    }
    .box-content-main{
        padding-top: 40px;
        text-align: center;
    }
    .location-hyd-mobile-menu{
        text-align: center;
    }
    .bar-menu-main-head{
        position: relative;
        top:0;
        left: 0;
    }
    .location-show-mobile{
        
        background: #795548;
        padding: 10px;
        border-radius: 8px;
        display: table;
        margin-left: 50px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .register-col-mobile{
        margin-top: -10px;
    }
    .ingredients-sec{
        padding: 30px 0;
    }
    .home-container-col{
        padding: 20px;
    }
    .description-row{
        padding-left: 0;
    }
    .register-form-second-row{
        padding-left: 0;
    }
    .register-form-select{
        margin-right: 0;
    }
    .register-col{
        padding-left: 15px;
        padding-right: 15px;
    }
    .new-timings-styles-h5{
        margin-top: 25px;
    }
}   
