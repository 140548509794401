
        .bg-primary-2 {
            background-color: #3779B3;
        }

        .bg-secondary-2 {
            background: #cbdbe8;
        }

        .text-underline {
            text-decoration: underline;
        }

        .text-bold {
            font-weight: 700;
        }

        .text-italic {
            font-style: italic;
        }

        .text-small {
            font-size: 0.8em;
        }

        .flex-grow-1 {
            flex-grow: 1;
        }

        #btn-top {
            position: fixed;
            z-index: 999;
            bottom: 1em;
            top: auto;
            left: auto;
            right: 1em;
            margin-left: -1.9rem;
            opacity: .66;
            display: none;
        }
        .instagram-feed1{
            height: 100%;
        }
        .insta-container{
            display: flex !important;
        }
        .instagram_biography{
            display: none;
        }
        .my-video-icon{
            max-width: 25px;
            position: sticky;
            bottom: -60px;
        }
        // .instagram_profile_image{
        //     display: none;
        // }
        // .instagram_profile{
        //     display: none;
        // }
        
//         .instagram-image {
//             width: 350px !important;
//             overflow: hidden !important;
//               /* -- Prevent flicker -- */
//   -webkit-backface-visibility: hidden !important;
//   -webkit-transform: translate3d(0, 0, 0) !important;
 
//         }

//         .instagram-image img{
//             max-width: 100% !important;
//             -webkit-transition: all 1s ease;
//             -moz-transition: all 1s ease;
//             -ms-transition: all 1s ease;
//             -o-transition: all 1s ease;
//             transition: all 1s ease;
//         }
//         .instagram-image img:hover {
//             max-width: 100% !important;
//             -webkit-transform: scale(1.25);
//             -moz-transform: scale(1.25);
//             -ms-transform: scale(1.25);
//             -o-transform: scale(1.25);
//             transform: scale(1.25);
//         }
        .social-main-bar{
           background-color: #afa07c;
            padding-top: 30px;
            padding-bottom: 10px;
          }
         .my-video-icon{
             max-width: 25px;
            
         }
         .myvideo-pin{
             background-image: url("../images/pin.svg");
             background-repeat: no-repeat;
            //  position: absolute;
            //  z-index: 9999999;
         }
        //  .eapps-instagram-feed-posts-item-type-video .eapps-instagram-feed-posts-item-image-icon-video {
        //     display: block;
        // }
        .video-camera{
            font-size: 25px;
            color: #fff;
        
        }
       
         .tab-content{
             position: relative;
         }         