.home-top-sec{
    background-image: url("../images/layer-4.jpg");
    // height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
}
.home-head{
    font-family: 'Burford Rustic Outline';
    font-size: 50px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    margin-left: 3px;
    letter-spacing: 1.25px;
    text-align: left;
    color: var(--white);
}
.home-head-s{
    font-family: 'Burford Rustic Book Ultra Light';
    font-size: 79px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: 0.76;
    margin-top: 35px;
    line-height: 0;
    letter-spacing: 1.98px;
    text-align: left;
    color: var(--white);
}
.home-head-beer{
    font-family: 'Burford Rustic Inline Bold';
    font-size: 198px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: 0.3;
    margin-left: -10px;
    line-height: 0;
    letter-spacing: 4.95px;
    text-align: left;
    color: #f4d386;
}
.home-head-amp{
    font-family: 'Burford Rustic Outline';
    font-size: 198px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.3;
    margin-top: 35px;
    margin-left: 35px;
    letter-spacing: -32px;
    text-align: left;
    color: var(--white);
}
.the{
    font-family: 'Burford Rustic Outline';
    font-size: 60px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 1.5px;
    text-align: left;
    color: var(--white);
}
.home-head-sp{
    font-family: 'Burford Rustic Book Ultra Light';
    font-size: 165px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: 0.36;
    line-height: 2.1;
    letter-spacing: 4.13px;
    text-align: left;
    color: var(--white);
}
.location-hyd{
    border-radius: 8px;
    // right: -104px;
    position: absolute;
    background: #795548;
    padding: 8px 10px;
    // margin-top: -30px;
    // bottom: 0;
    top:60px;
    right: 100px;
}
.cus-select{
    background: #795548;
    border: transparent;
    color: #fff;
    letter-spacing: 0.90px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important; 
}
.cus-select option{
    letter-spacing: 0.90px;
    -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
}
.location-img{
    width: 20px;
    // position: absolute;
    margin-top: 1px;
    margin-left: 0px;
}
.toGether-sec{
    background:#fff8ed;
    padding-top: 70px;
    padding-bottom: 50px;
}
.peg-img{
    position: absolute;
    top: -45px;
    right: -104px;
}
.toGether-bg{
    background-image: url("../images/layer-8.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 18px;
}
.play-img-df{
    padding: 118px 144px;
}
.text-toGether{
    // font-family: 'Burford Rustic Inline Light';
    font-family: 'Burford Rustic Inline Bold';
    font-size: 72px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 1.8px;
    text-align: left;
    color: #442820;
}
.m-t-toGether{
    margin-top: 35px;
}
.hr-text{
    margin-top: 40px;
    width: 110px;
    border-top: 1px solid;
    float: left;
    margin-left: 10px;
}
.toGether-content{
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 2.4px;
    text-align: left;
    color: #494340;
    margin-top:75px;
    margin-left: 10px;
}
.toGether-content-s{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 1.4px;
    text-align: left;
    color: #494340;
    margin-left: 10px;
}
.place{
    padding: 0px 30px;
    padding-right: 125px;
}
.toGether-beer{
    font-family: 'Burford Rustic Book Bold';
    font-size: 150px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 3.75px;
    text-align: center;
    color: #513831;
}
.toGether-beer-c{
    font-family: 'Burford Rustic Book Bold';
    font-size: 65px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: 0.4;
    letter-spacing: 3.75px;
    text-align: center;
    margin-left: 10px;
    color: #513831;
}
.toGether-beer-main{
    font-family: 'Burford Rustic Book Bold';
    font-size: 150px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 0.75px;
    
    color: #513831;
}
.toGether-beer-c-main{
    font-family: 'Burford Rustic Book Bold';
    font-size: 65px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    // line-height: 0.4;
    letter-spacing: 3.75px;
    
    margin-left: 10px;
    color: #513831;
}
.toGether-hr-m{
    margin-left: 20px;
}
.our-Beer-sec{
    border-left: 1px solid gray;
    margin-left: 3px;
}
.box-sec{
    padding-bottom: 150px;
    padding-top: 50px;
}

.box-sec-right{
    border-right: 1px solid #E0E0E0;
}
.box-content{
    font-family: 'Burford Rustic Book Bold';
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #917251;
    padding-top: 80px;
}
.box-fr-img{
    width: 22px;
    margin-left: 4px;
}
.box-s-g-img{
    background-image: url("../images/beerGlass.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.ingredients-sec{
    background:#eeeeee;
    background-repeat: no-repeat;
    background-size: cover;   
    padding: 75px 0px;
}
.ingredients-c{
    font-family: 'Burford Rustic Book Bold';
    font-size: 60px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 1.5px;
    text-align: center;
    color: #442820;
}
.ingredients-sub-c{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    margin-top: 60px;
    letter-spacing: 1.4px;
    text-align: center;
    color: #494340;
}
.ingredient-box{
    background: white;
    margin-top: 50px;
    padding: 10px 20px;

}
.ingredinets-box-head{
    font-family: 'Burford Rustic Book Bold';
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: left;
    color: #150602;
}
.ingredinets-box-c{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: 0.4px;
    text-align: left;
    color: #494340;
}
.semicircel{
    height: 20px;
    width: 30px;
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    position: absolute;
    margin-left: -45px;
    margin-top: -38px;
    transform: rotate(270deg);
}
.semicircel1{
    height: 20px;
    width: 30px;
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    position: absolute;
    margin-left: 320px;
    margin-top: -38px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.extra-space{
    padding: 31px;
}
.hyd-loc-video{
    width: 100%;
    cursor: pointer;
    // height: 400px;
}
.hyd-loc-video-h3{
    font-size: 72px;
    line-height: 60px;
    letter-spacing: 1.8px;
    color: #442820;
  
    font-family: 'Burford Rustic Book Bold';
}
// .hyd-loc-video-text-col{
   
// }
.happening{
    background-image: url("../images/leather.jpg");
    height: 400px;
}
.hyd-home-happen-col{
    padding: 0;
}
.hyd-home-happen-row{
    margin-top: -160px;
}
.happening-head{
    font-family: 'Burford Rustic Book Bold';
    font-size: 60px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 3px;
    text-align: left;
    color: #f4d386;
    margin-top:50px;
}
.loaction-head-s{
    font-family: 'Burford Rustic Book Bold';
    font-size: 60px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 1.5px;
    text-align: center;
    color: #442820;
    padding-bottom: 40px;
}
.location-1{
    background-image: url("../images/location-1.png");
    background-repeat: no-repeat;
    background-size: cover;   
    padding-top: 350px;
    padding-bottom: 30px;
    padding-left: 30px;
}
.location-2{
    background-image: url("../images/location-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;   
    padding-top: 350px;
    padding-bottom: 30px;
    padding-left: 30px;
}
.location-3{
    background-image: url("../images/location-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;   
    padding-top: 350px;
    padding-bottom: 30px;
    padding-left: 30px;
}
.location-1-c{
    font-family: 'Burford Rustic Book Bold';
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: var(--white);
}
.location-1-head{
    font-family: 'Burford Rustic Book Bold';
    font-size: 55px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.28px;
    text-align: left;
    color: var(--white);
    margin-top: -25px;
}
.enquiry{
    font-family: 'Open Sans', sans-serif;
    padding: 5px 10px;
    background: #fff;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    text-align: center;
    margin-left: 4px;
    color: #040301;
}
.enquiry:hover{
    text-decoration: none;
    color: #040301;
}
.direction{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    margin-left: 4px;
    color: #fff;
}
.direction-e:hover{
    text-decoration: none;
    color: #fff;
}
.end-box{
    margin-top: 10px;
}
.news-sec{
    background-color: #2a2a2a;
}
.our-location{
    margin-top: 20px;
    margin-bottom: 60px;
}
// 


.hamburger{
    position: absolute;
    cursor: pointer;
    right: 5px;
    top: 80px;
    transform: translate(-5%, -50%);
    z-index: 2;
}
.line1{
    width: 40px;
    height: 5px;
    background: #8c5626;
    margin: 5px;
    border-radius: 5px;
}
.line2{
    width: 30px;
    height: 5px;
    background: #8c5626;
    margin: 5px;
    border-radius: 5px;
    margin-left: 15px;
}
.line3{
    width: 32px;
    height: 5px;
    background: #8c5626;
    margin: 5px;
    border-radius: 5px;
    margin-left: 13px;
}
//video css start
.our-Beer-sec{
    .video-col{
        padding-left: 0;
        padding-right: 0;
    }
    .thumb{
       cursor: pointer;
    }
    
    video{
        float: left;
        position: relative;
    
       width: 100%;
        height: auto;
        opacity: 0;
        transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000); 
    }
    
    video:hover{
        opacity: 1;
        transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }
    .video-text{
        position: absolute;
        z-index: 99;
        display: block;
        padding-left: 35px;
    }
    .thumb:hover .video-text {
         opacity: 0;
    }
    
}
.our-hr-text{
    margin-top: 70px;
    width: 110px;
    border-top: 1px solid;
    float: left;
    margin-left: 10px;
}

//video css end
.text-img{
    margin-top: 70px;
    width: 100%;
}

.hyd-loc-btn:hover{
    text-decoration: none;
}
.whole-loc-a:hover{
    text-decoration: none;
}
.error{
    color: red;
}
.cus-select{
    cursor: pointer;
}
.box-sec-main {
    padding-bottom: 150px;
    padding-top: 230px;
}
.box-content-main{
    font-family: 'Burford Rustic Book Bold';
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #917251;
    padding-top: 120px;
}
.ingredinets-box-head-sor{
    font-family: 'Burford Rustic Book Bold';
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 70px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #150602;
}
.semicircel-sor{
    height: 20px;
    width: 30px;
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    position: absolute;
    margin-left: -45px;
    margin-top: -48px;
    transform: rotate(270deg);
}
.hyd-rocky{
    background-image: url("../images/rocky-red-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.hyd-hur{
    background-image: url("../images/prost-hyd-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.hyd-stone{
    background-image: url("../images/burnt-shore-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.hyd-red{
    background-image: url("../images/red-alert-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.hyd-brew{
    background-image: url("../images/brewers-special-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.hyd-cider{
    background-image: url("../images/cider-rider-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bang-fly{
    background-image: url("../images/fly-high-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bang-bolt-bg{
    background-image: url("../images/bang-bolt-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bang-lit{
    background-image: url("../images/lyt-bae-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bang-rocky-bg{
    background-image: url("../images/rocky-red-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}

.bang-stone{
    background-image: url("../images/burnt-shore-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bang-red{
    background-image: url("../images/red-alert-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bang-special{
    background-image: url("../images/brewers-special-beer-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bang-cider{
    background-image: url("../images/cider-rider-glass-1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.bar-menu-tabs-mob{
   display: none;
}
.mobile-bear-glass{
    display: none;
}
.location-show-mobile{
    display: none;
}
// custome select
.dropDownLocation{
    .dropdown:hover>.dropdown-menu {
        display: block;
      }
      
      .dropdown>.dropdown-toggle:active {
        /*Without this, clicking will make it sticky*/
          pointer-events: none;
      }
      .dropdownMenu-location{
        background: transparent;
        border: navajowhite;
        margin-left: 15px;
        height: 10px;
        color: #fff !important;
        margin-top: -30px;
      }
      .dropdownMenu-location:hover{
        background-color: transparent;
        border-color: transparent;
      }
      .dropdownMenu-location:focus{
        box-shadow: 0 0 0 0.2rem transparent;
      }
}


// new-bang
.music-start-img{
    max-width: 100%;
    margin-top: 50px;
}
.tickets-left-div{
    background: #fff;
    // padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left:10px;
    padding-right:10px;
}
.tickets-left-text{
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1.4px;
    color: #494340;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}
.book-now-a{
    text-decoration: none;
  
    float: right;
    // 
    background: #7a5447;
    border: none;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 3.5px;
    position: relative;
    z-index: 1;
    padding: 5px 12px;
   
}
.book-now-a:hover{
    text-decoration: none;
    color: #fff;
}