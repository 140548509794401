.rocky-road-main-sec{
    background-image: url("../images/paper.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0;
}
.beer-detail-menu{
    background-color: #fff;
    padding-left: 38px;
    padding-top: 10px;
    padding-bottom: 8px;
   
}
.beer-detail-menu-h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 4.5px;
    line-height: 30px;
    padding-top: 12px;
    
}
.beer-detail-menu-h1 .arrow-span{
    position: absolute;
    left: -999999px;
    z-index: 9999;
   
}
.show-arrow-span{
    width: 25px;
    height: 15px;
    margin-left:-30px;
}
.beer-detail-menu-h1:hover .arrow-span {
    left:22px;
}
.beer-detail-menu-h1:hover{
    color:#8f7150;
    cursor: pointer;
}
.arrow-span{
    width: 25px;
    height: 15px;
    margin-top: 6px;
}
.rock-road-h3{
    font-size: 79px;
    margin-left: -15px;
    margin-top: 15px;
  line-height: 60px;
  // padding: 10px 0;
  letter-spacing: 1.98px;
  color:#524840;
  font-family: 'Burford Rustic Book Ultra Light';
}
.beer-name{
    font-size: 38px;
    padding-top: 50px;
    line-height: 40px;
    // padding: 10px 0;
    letter-spacing: 0.8px;
    color:#524840;
    font-family: 'Burford Rustic Book Ultra Light';
}
.rocky-road-main-row{
    margin-top: 50px;
}
.rock-road-p{
    color: #000;
    font-size: 25px;
    font-family: 'Burford Rustic Book Black';
    letter-spacing: 0.12px;
    margin-bottom: 0;
    font-weight: 100;
  
}
.rocky-road-col{
    padding-left: 100px;
}
.rocky-road-sec-two{
    background-image: url('../images/rocky-road-bg-two.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 80px;
    padding-top: 20px;
}
.beer-details-img-col{
    position: relative;
    padding-bottom: 50px;
   
}

// .beer-details-bg-circle-p{
//     text-align: center;
// }
.beeer-details-glass-p{
    text-align: center;
}
.beer-details-bg-circle{
    width: 100%;
    max-width: 100%;
}
.beeer-details-glass{
    position: absolute;
    // right: 200px;
    // top: 15%;
    
    right: 150px;
    top: 50px;
    bottom: 0;
   
}
.rocky-road-active{
    color: #8f7150;
}
.alc-p{
    color: #f3ebd6;
    letter-spacing: 1.8px;
    background-color: #907251;
    border-radius: 30px;
    max-width: 50px;
    margin-top: 50px;
    text-align: center;
}
// .beer-percentage{
//     font-family: 'Burford Rustic Book Ultra Light';
//     font-size: 130px;
//     line-height: 100px;
//     // margin-top: -50px;
//     margin-left: -20px;
//     letter-spacing: 1.8px;
//     color: #524840;
// }
.beer-percentage{
    font-family: 'Burford Rustic Book Ultra Light';
    font-size: 100px;
    line-height: 100px;
    // margin-top: -50px;
    margin-left: 0;
    letter-spacing: 1.8px;
    color: #524840;
}
.broze-factor{
    font-family: 'Burford Rustic Book Ultra Light';
    font-size: 70px;
    line-height: 80px;
    margin-top: -30px;
    margin-left: 0px;
    letter-spacing: 1.8px;
    color: #524840;
}
.to-span{
    padding: 20px;
}
.beer-details-div{
    margin-top: 32px;
}
.beer-details-malt{
    color: #000;
    font-size: 25px;
    font-family: 'Burford Rustic Book Black';
    letter-spacing: 0.12px;
    margin-bottom: 0;
    font-weight: 100;
    // padding-top: 50px;
}
.beer-a{
    text-decoration: none;
    color: #000;
}
.beer-a:hover{
    text-decoration: none;
    
}
// 
