#landingVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
  }
  
  .landingcontent {
    position: fixed;
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
    height: 100%
  }
  
  .langing-up-flex {
      display: table;
      width: 100%;
      height: 100%;
  }
  .langing-up-flex-semantic {
      display: table-cell;
      vertical-align: middle;
      padding-left: 50px;
      text-align: center;
  }
  .landing-row1{
      margin-bottom: 100px;
  }
  #sound-img{
        bottom: 30px;
      position: absolute;
      cursor: pointer;
  }
  #un-sound-img{
    bottom: 30px;
  position: absolute;
  cursor: pointer;
}
.location-hyd-btn{
  background-color: #795548;
  border:none;
  color: #fff;
  letter-spacing: 0.8px;
}